import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import PersonRow from '../../components/PersonRow/PersonRow'

import Virginia from '../../imageComponents/virginiaW'
import Hugh from '../../imageComponents/hughH'
import Mary from '../../imageComponents/maryKB'
import Av from '../../imageComponents/avMaharaj'
import Marg from '../../imageComponents/margR'
import Terence from '../../imageComponents/terenceYoung'
import Rinku from '../../imageComponents/rinkuDeswal'
import Sam from '../../imageComponents/samBiasucci'
import BohodarW from '../../imageComponents/bohodarW'

const BoardPage = () => {
  const intl = useIntl()

  return (
    <Layout
      breadcrumb={{
        text: <FormattedMessage id='nav.about' />,
        path: '/about/'
      }}
      header={<FormattedMessage id='nav.board' />}
    >
      <SEO
        description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.board' })}
      />
      <Container>
        <Row>
          <Col>
            <p>
              <FormattedMessage id='board.content01' />
              <br />
              <br />
              <FormattedMessage id='board.content02' />
            </p>
          </Col>
        </Row>

        <PersonRow image={<Terence />}>
          <h3 className='underline'>
            <span class='sm uppercase'>
              <FormattedMessage id='board.chair' />
            </span>
            <br />
            <span class='thin'>Terence</span> Young
          </h3>
          <p>
            <FormattedMessage id='board.terence' />
          </p>
        </PersonRow>

        <PersonRow image={<Sam />}>
          <h3 className='underline'>
            <span class='sm uppercase'>
              <FormattedMessage id='board.viceChair' />
            </span>
            <br />
            <span class='thin'>Salvatore “Sam”</span> Biasucci
          </h3>
          <p>
            <FormattedMessage id='board.sam' />
          </p>
        </PersonRow>

        <PersonRow image={<Rinku />}>
          <h3 className='underline'>
            <span class='thin'>Rinku</span> Deswal
          </h3>
          <p>
            <FormattedMessage id='board.rinku' />
          </p>
        </PersonRow>

        <PersonRow image={<Hugh />}>
          <h3 className='underline'>
            <span class='thin'>Hugh</span> Heron
          </h3>
          <p>
            <FormattedMessage id='board.hugh' />
          </p>
        </PersonRow>

        <PersonRow image={<Mary />}>
          <h3 className='underline'>
            <span class='sm uppercase thin'>
              CHAIR - GOVERNANCE, PEOPLE AND CULTURE COMMITTEE
            </span>
            <br />
            <span class='thin'>Mary</span> Kardos Burton
          </h3>
          <p>
            <FormattedMessage id='board.mary' />
          </p>
        </PersonRow>

        <PersonRow image={<Marg />}>
          <h3 className='underline'>
            <span class='sm uppercase thin'>
              CHAIR - FINANCE, AUDIT AND RISK COMMITTEE
            </span>
            <br />
            <span class='thin'>Marg</span> Rappolt
          </h3>
          <p>
            <FormattedMessage id='board.marg' />
          </p>
        </PersonRow>

        <PersonRow image={<BohodarW />}>
          <h3 className='underline'>
            <span class='thin'>Bohodar</span> Rubashewsky
          </h3>
          <p>
            Bohodar Rubashewsky is a respected public sector leader with
            extensive experience in program delivery, people leadership,
            regulatory compliance, corporate services and business
            transformation. Following his retirement from the Ontario Public
            Service in 2018, Bohodar served for five years as Vice President -
            People, Corporate Innovation and Services at Holland Bloorview Kids
            Rehabilitation Hospital. His government career spanned over 30 years
            at the provincial and federal levels, including 15 years across a
            wide array of senior executive roles in six government ministries as
            well as the Ontario Lottery and Gaming Corporation. Bohodar has been
            a member of three public sector boards of directors and is a
            graduate of the Rotman School of Management’s ICD Directors
            Education Program as well as CPA Canada’s Senior Executive Advanced
            Finance and Accounting Program.
          </p>
        </PersonRow>
      </Container>
    </Layout>
  )
}

export default BoardPage
